import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { ReactComponent as Phone } from "../img/hero-phone.svg";
import { ReactComponent as Mail } from "../img/hero-letter.svg";
import { ReactComponent as Pin } from "../img/hero-pin.svg";
import { ReactComponent as Fax } from "../img/hero-fax.svg";

const Herosection = ({ herosection, contact }) => {
  const data = useStaticQuery(graphql`
    query CareerQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "praxis-page" } }) {
        frontmatter {
          career {
            show
            positions {
              position
            }
          }
        }
      }
    }
  `);

  const telephoneLink =
    "tel:+49" + herosection.telephone.replace(/\D/g, "").slice(1);

  const subtitleChunkOne = herosection.subtitle.split("Dres.")[0];
  const subtitleChunkTwo = "Dres. " + herosection.subtitle.split("Dres.")[1];

  return (
    <div id="herosection">
      <div
        class="px-20p sm:px-10"
        style={{
          backgroundImage:
            "linear-gradient(90deg, rgba(0,34,82) 0%, rgba(0,106,255) 100%), radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)",
          backgroundBlendMode: "multiply",
        }}
      >
        <div class="max-w-900p mx-auto w-full relative">
          <div
            class={`text-white ${data.markdownRemark.frontmatter.career.show ? "pt-32" : "pt-50p"
              } sm:pt-60p pb-50p sm:pb-60p`}
          >
            <h1 class="mini2:w-8/12 sm:w-9/12 font-medium text-15152 sm:text-24242">
              {herosection.title}
            </h1>
            <div class="h-0.5 sm:w-2/3 lg:w-[64.25%] bg-white my-25p sm:my-40p" />
            <h2 class="font-medium text-10909 pb-15p sm:pb-30p">
              {subtitleChunkOne} <br />
              {subtitleChunkTwo}
            </h2>
            <div class="flex flex-col md:flex-row text-10909">
              <div class="flex flex-col md:mr-40p">
                <a class="flex items-center mb-15p" href={telephoneLink}>
                  <Phone class="w-20p h-20p mr-15p" />
                  {herosection.telephone}
                </a>
                <div class="flex items-center mb-15p md:mb-0 no-underline decoration-none">
                  <Fax class="w-20p h-20p mr-15p" />
                  {herosection.fax}
                </div>
              </div>
              <div class="flex flex-col text-10909">
                <a
                  class="flex items-center mb-15p"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={contact.googleMapsLink}
                >
                  <Pin class="w-20p h-20p mr-15p" />
                  {herosection.address}
                </a>
                <a
                  class="flex items-center mb-15p md:mb-0"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${herosection.email}`}
                >
                  <Mail class="w-20p h-20p mr-15p" />
                  {herosection.email}
                </a>
              </div>
            </div>
            <p class="text-10909 mb-15p md:mt-15p">Wir bieten Ihnen nun auch die Möglichkeit zur direkten Terminvereinbarung, zunächst nur für Magenspiegelungen. Alle weiteren Terminwünsche wie z.B. für Sprechstunde, Blutentnahme oder Darmspiegelung bitten wir bis auf Weiteres telefonisch oder über den Button "Weitere Terminanfragen" mitzuteilen.</p>
            <div class="flex flex-col md:flex-row">
            <a href="https://webtermin.medatixx.de/#/3bee74f2-c56c-412f-8871-90ca9e998b78" target="_blank" style="width: fit-content; text-decoration: none !important;" class="mb-15p md:mb-0 md:mr-15p block no-underline rounded py-1.5 px-5 sm:mt-5 text-base font-medium text-primary bg-white text-center transition-transform duration-250 ease-in-out hover:scale-110">Online Buchung einer Magenspiegelung</a>
            <a href="#termin" style="width: fit-content; text-decoration: none !important;" class="mb-15p md:mb-0 block no-underline rounded py-1.5 px-5 sm:mt-5 text-base font-medium text-primary bg-white text-center transition-transform duration-250 ease-in-out hover:scale-110">
              Weitere Terminanfragen
            </a>
            </div>

          </div>
          {/* disabled by false */}
          {false && data.markdownRemark.frontmatter.career.positions && data.markdownRemark.frontmatter.career.positions.length !== 0 && data.markdownRemark.frontmatter.career.show && (
            <Link
              // to="/praxisteam#karriere"
              to="/uploads/media/stellenangebot.pdf"
              id="mitarbeiterButton"
              class="bg-cta hover:bg-[#E46706] hover:no-underline h-28 w-28 sm:h-40 sm:w-40 rounded-full absolute -top-4 sm:-top-7 right-0 sm:right-5 transform rotate-12 text-center flex justify-center items-center"
            >
              <p class="text-white font-medium text-09697 sm:text-12121">
                {herosection.careerText}
              </p>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Herosection;
